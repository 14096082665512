<template>
  <!--
    x-vue-csv-supported: false
    x-vue-table-show: true
    fields: [id, spaceName, created, customFields, tags, metadata, description, whiteListIPs, key, role, constraints]
    x-vue-custom-fields: [ customFields ]
    x-vue-tags: [ tags ]
    x-vue-metadata: [ metadata ]
    -->
  <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="apiKeys"
  />
</template>
<script>
  import { apiKeys } from '@/config/descriptions/apiKeys';
  import { apiKeysTexts } from '@/config/pageTexts/apiKeys.json';

  export default {
    name: 'ApiKeysTable',
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'apiKeys/apiKeys',
            fields: 'apiKeys/fields',
            sortableFields: 'apiKeys/sortableFields',
            pages: 'apiKeys/pages',
            loading: 'apiKeys/loading',
            resultCount: 'apiKeys/resultCount',
            totalRecords: 'apiKeys/totalRecords',
            originalFields: 'apiKeys/originalFields',
            searchableAllFields: 'apiKeys/searchableAllFields',
            success: 'apiKeys/success',
            message: 'apiKeys/message',
          },
          actions: {
            fields: 'apiKeys/handleFields',
            delete: 'apiKeys/handleDeleteApiKeys',
            reset: 'apiKeys/handleClearFilter',
            query: 'apiKeys/handleGetApiKeysByQuery',
          },
        },
        settings: {
          describe: { ...apiKeys },
          page: { ...apiKeysTexts },
        },
        route: {
          create: 'CreateApiKey',
          edit: 'EditApiKey',
          preview: 'PreviewApiKey',
        },
      };
    },
  };
</script>