<template>
  <ApiKeysTable/>
</template>
<script>
import ApiKeysTable from '@/generated/ziqni/views/apiKeys/ApiKeysTable';

export default {
  components: {
    ApiKeysTable
  },
  props: {}
};
</script>
